.faq-and-contact-page {
	h2 {
		text-align: center;
	}
	@include responsive($page-breakpoint-mobile) {
		> section {
			padding: 0;
		}
	}
	.category-list {
		width: 100%;
		padding: 0;

		> li {
			background-color: lighten($color-brand-2, 30%);
			padding: 15px 20px;
			cursor: pointer;
			position: relative;
			margin: 0 0 1.5em;
			&:hover {
				background-color: lighten($color-brand-2, 20%);
			}
			&:last-child {
				margin-bottom: 0;
			}
			> a {
				display: flex;
				justify-content: space-between;
			}
			.subcategory-list {
				list-style-type: none;
				border-top: 2px solid $color-primary;
				border-bottom: 2px solid $color-primary;
				padding: 3em 1.5em;
				margin-top: 1.5em;
				font-weight: 600;
				background-color: $color-white;

				> li {
					margin-bottom: 2em;

					cursor: default;
					&:last-child {
						margin-bottom: 0;
					}
					.faq-answer {
						padding: 1em 1.5em;
						margin-top: 2em;
						font-weight: 400;

						> img:first-child,
						> p:first-child > img:first-child {
							display: none;
						}

						p {
							margin-bottom: 1em;
							&:last-child {
								margin-bottom: 0;
							}
							img {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}

	.contact-section {
		align-items: center;
		h2 {
			margin-top: 0;
		}
		@include responsive($page-breakpoint-mobile) {
			.contact-header {
				background-color: $color-primary;
				width: 100%;

				h2 {
					margin: 2em;
					color: $color-white;
				}
			}
			p {
				margin-top: 3em;
				padding: 0;
			}
		}

		.ContactForm {
			width: 50%;
			margin: 2em 0 0;

			@include responsive($breakpoint-tablet) {
				width: 70%;
				padding-bottom: $page_padding-vertical * 1.5;
			}

			@include responsive($page-breakpoint-mobile) {
				width: 100%;
				padding: 0 $page_padding-horizontal * 1 $page_padding-vertical * 1.5;
			}

			.TextArea {
				height: 150px;
				padding: 1em;
			}

			.Button {
				display: block;
				text-align: center;
				margin: 2.5em auto 0;
			}

			#google-recaptcha-checkbox-container {
				margin-top: $spacing-xl;
			}
		}
	}
}
