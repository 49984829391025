// -----------------------------------------
// Introduction section in free resources article page
// -----------------------------------------

.introduction-container {
	background-color: $color-brand-1;
	color: $color-white;
	margin: 2rem 0 0;
	padding: $page_padding-vertical $page_padding-horizontal;

	.link-group {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;

		a {
			flex: 1;
			text-align: center;

			&:not(:last-child) {
				margin-right: 10px;
			}
		}

		@include responsive(68rem) {
			display: flex;
			flex-direction: column;
			align-items: center;
			a {
				width: 60%;
				margin-bottom: 1rem;

				&:not(:last-child) {
					margin-right: 0;
				}
			}
		}
		@include responsive(45rem) {
			a {
				width: 100%;
			}
		}
	}
}
