$page-spacing: 2rem;
$button-size: 3rem;
$column-gap: $page-spacing * 2;

.testimonial-container {
	--testimonial-quote-count: 3;

	@include responsive($breakpoint-large-desktop) {
		--testimonial-quote-count: 3;
	}
	@include responsive(75rem) {
		--testimonial-quote-count: 2;
		min-height: unset;
	}
	@include responsive(40rem) {
		--testimonial-quote-count: 1;
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;
	z-index: 2;
	position: relative;

	.testimonial-carousel {
		display: flex;
		overflow-x: auto;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;
		gap: $column-gap;
		position: relative;

		&::-webkit-scrollbar {
			display: none;
		}

		.testimonial-item {
			flex: 0 0
				calc((100% - (#{$column-gap} * (var(--testimonial-quote-count) - 1))) / var(--testimonial-quote-count));

			flex-direction: column;
			padding: $page-spacing $page-spacing 0;
			gap: $page-spacing;
			display: flex;

			> * {
				margin: 0;
			}

			.testimonial-text {
				font-size: 1rem;
			}

			.testimonial-author {
				font-size: 0.9rem;
				font-weight: 700;
				.name {
					letter-spacing: 0.03em;
				}
				.location {
					padding-left: 0.15em;
				}
			}
		}
	}
}
