.homepage {
	--vertical-padding: #{$page_padding-vertical * 2};
	--horizontal-padding: #{$page_padding-horizontal * 2};
	--content-max-width: 1280px;

	section {
		padding: $page_padding-vertical $page_padding-horizontal * $section-horizontal-padding-multiplier;
		width: 100dvw;
		border-bottom: 2px solid $color-white;
		position: relative;
		z-index: 1;

		@include responsive($breakpoint-tablet) {
			padding: $page_padding-vertical $page_padding-horizontal * 2;
		}
		@include responsive($page-breakpoint-mobile) {
			padding: $page_padding-vertical $page_padding-horizontal;

			h2 {
				text-align: center !important;
			}
		}
	}

	.content {
		max-width: var(--content-max-width);
		margin: 0 auto;
		padding: 0 0 var(--vertical-padding);
	}

	section.hero-section {
		@include responsive($breakpoint-tablet) {
			padding-top: $page_padding-vertical * 2;
		}
	}

	.hero-section {
		min-height: 100dvh;
		position: relative;
		background-image: url('/images/backgrounds/pam-baby-mother_on_deck.avif');
		background-size: cover;
		background-position: center;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		@include overlay(rgb(9, 36, 13), 0.8);

		.hero-content {
			position: relative;
			max-width: var(--content-max-width);
			z-index: 2;
			margin: $page_padding-vertical * 1.5 $page_padding-horizontal * 3.5 0;
			text-align: center;

			> p {
				margin-left: $page_padding-horizontal * 2.5;
				margin-right: $page_padding-horizontal * 2.5;
			}

			.scroll-arrow {
				.arrow-down {
					transition: transform 0.3s ease;
				}
				&:hover {
					.arrow-down {
						transform: scale(1.2);
					}
				}
			}
		}
		@include responsive($breakpoint-tablet) {
			.hero-content {
				margin: 0;
				> p {
					margin-left: $page_padding-horizontal;
					margin-right: $page_padding-horizontal;
				}

				.scroll-arrow {
					margin-top: 1rem;
				}
			}
		}
		@include responsive($page-breakpoint-mobile) {
			background-position: 30% center;
			.hero-content {
				h1 {
					font-size: 1.8rem;
				}
				> p {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
	}

	// -----------------------------------------
	// Program Section
	// -----------------------------------------

	$program-card-gap: 4rem;
	$program-card-height-large: 380px;
	$program-card-border-radius-large: $program-card-height-large / 2;

	.programs-section {
		text-align: center;

		.programs-group {
			margin-bottom: $program-card-gap;
			align-items: center;

			.icons-row {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				gap: 2rem;
				margin-bottom: $program-card-gap;

				@include responsive(75rem) {
					grid-template-columns: repeat(2, 1fr);
				}

				@include responsive(40rem) {
					grid-template-columns: 1fr;
					justify-items: center;
				}

				div {
					display: flex;
					align-items: center;
					text-align: left;

					svg {
						margin-right: 0.8rem;
						flex-shrink: 0;
					}

					@include responsive(40rem) {
						max-width: 25rem;
						width: 100%;
						justify-content: flex-start;
					}
				}
			}
		}

		--program-card-direction: row;
		--program-card-width: 100%;

		@include responsive($breakpoint-tablet) {
			--program-card-direction: column;
			--program-card-width: 80%;
		}

		@include responsive($page-breakpoint-mobile) {
			--program-card-width: 100%;
		}

		.program-card {
			display: flex;
			flex-direction: var(--program-card-direction);
			width: var(--program-card-width);
			margin: 0 auto $program-card-gap;
			overflow: hidden;
			background-color: currentColor;
			height: $program-card-height-large;
			border-radius: $program-card-border-radius-large;

			@include responsive(75rem) {
				height: auto;
			}

			.program-left,
			.program-right {
				flex: 1;
			}

			.program-left {
				position: relative;
				background-size: cover;
				background-position: center;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $page-spacing;
				img {
					z-index: 2;
					width: 250px;
					height: auto;
				}
			}

			.program-right {
				padding: $page_padding-vertical $page_padding-horizontal * 3.5 $page_padding-vertical
					$page_padding-horizontal;
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: left;
				color: $color-white;

				.button {
					align-self: flex-start;
				}
			}
		}

		@include responsive($breakpoint-tablet) {
			.program-card {
				height: auto;
				border-radius: 0;
				border-top-left-radius: 250px;
				border-top-right-radius: 250px;
				border-bottom-left-radius: $program-card-border-radius-bottom;
				border-bottom-right-radius: $program-card-border-radius-bottom;

				.program-right {
					padding: $page_padding-vertical $page_padding-horizontal;
				}
				.program-left {
					padding-top: $page_padding-vertical * 2;
					padding-bottom: $page_padding-vertical * 2;
				}
			}
		}

		@include responsive(30rem) {
			.program-card {
				width: 100%;
				.program-right {
					padding: $page_padding-vertical * 0.6 $page_padding-horizontal;
					text-align: center;
				}
				.program-left {
					img {
						width: 200px;
						height: auto;
					}
				}
			}
		}

		.ndc .program-left {
			background-image: url('/images/accent-photos/health-professional.avif');
			@include overlay(rgba(117, 87, 115), 0.6);

			@include responsive($breakpoint-tablet) {
				background-position: center 45%;
			}
		}

		.psp .program-left {
			background-image: url('/images/accent-photos/baby-sleeping.webp');
			@include overlay(rgba(64, 110, 142), 0.6);
		}

		.bsb .program-left {
			background-image: url('/images/accent-photos/breastfeeding.avif');
			@include overlay(rgba(157, 114, 121), 0.6);
		}

		.ndc {
			color: $color-ndc-chineseViolet;
		}

		.psp {
			color: $color-psp-blueDark;
		}

		.bsb {
			color: $color-bsb-copperRose;
		}
	}

	// -----------------------------------------
	// Evidence Based Section
	// -----------------------------------------

	.evidence-based-section {
		position: relative;
		z-index: 0;

		.image {
			position: absolute;
			top: -10%;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			pointer-events: none;
			overflow-x: clip;
			img {
				height: auto;
				width: 50%;
				object-fit: contain;
				mask-position: right center;

				@include responsive($page-breakpoint-desktop) {
					position: absolute;
					left: 0;
					top: 0%;
					width: 60%;
				}

				@include responsive($breakpoint-tablet) {
					top: 4%;
					width: 80%;
				}

				@include responsive($page-breakpoint-mobile) {
					width: 150%;
					left: -30%;
					top: 4%;
				}

				@include responsive(21.5rem) {
					top: 5%;
				}
			}
		}

		.container {
			max-width: var(--content-max-width);
			margin: 0 auto;
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: column;
			gap: $page-spacing * 4;
			@include responsive($breakpoint-tablet) {
				gap: $page-spacing * 3;
			}
		}

		.content-evidence {
			max-width: 550px;
			margin-left: auto;

			@include responsive(75rem) {
				max-width: 100%;
				margin-left: 0;
			}
		}

		.testimonials {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 5rem;

			@include responsive(65rem) {
				gap: 5rem;
			}

			@include responsive(55rem) {
				flex-direction: column;
			}

			.testimonial {
				flex: 1;
				max-width: 500px;
				margin: 0 auto;
				text-align: left;

				@include responsive(55rem) {
					text-align: center;
				}

				p {
					margin-bottom: 0;
					padding: 0.5rem 0;
				}
			}
		}
	}

	// -----------------------------------------
	// Dr Pam Section
	// -----------------------------------------

	.dual-content-section {
		position: relative;

		.image-container-light-bg {
			@include responsive($page-breakpoint-mobile) {
				margin-top: 3em;
			}
		}
		@include responsive($page-breakpoint-mobile) {
			h2 {
				margin-top: 0;
			}
		}
		.social-links {
			display: flex;
			gap: 3rem;
			margin-top: 2.5rem;
			@include responsive($page-breakpoint-mobile) {
				flex-direction: column;
				gap: 1.5rem;
			}

			a {
				display: flex;
				align-items: center;
				gap: 1rem;
				text-decoration: none;

				span {
					font-weight: 500;
					letter-spacing: 0.07em;
				}
			}
		}
	}
}
