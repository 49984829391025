$quote-horizontal-spacing: -1.5rem;
$indent: 4rem;

.quote-style-bg {
	position: relative;
	margin-bottom: 0.5em;
	font-size: 1rem;
	font-style: italic;
	&:before {
		content: '';
		position: absolute;
		top: -1rem;
		z-index: 1;
		height: 2.8rem;
		width: 3.8rem;
		background-image: url(/images/quote-light_bg-lg.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	&:before {
		left: $quote-horizontal-spacing;
	}
}

.quote-container {
	margin-left: auto;
	width: calc(100% - #{$indent * 2});
	margin-right: auto;
}

.quote-source {
	font-weight: 600;
	a {
		font-style: italic;
		margin-left: 0.5em;
	}
}

.quote-container,
.quote-style,
.quote-source {
	font-size: 0.8rem;
}
