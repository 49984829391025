body.menu-open {
	overflow: hidden;
}

$menu-padding-top: $page-spacing * 0.6;
$menu-padding-sides: $page-spacing * 0.75;
$item-gap: $menu-padding-sides * 2;

header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $page_padding-vertical * 0.5 $page_padding-horizontal;
	position: fixed;
	width: 100%;
	box-sizing: border-box;
	z-index: 100;
	@include responsive(45rem, max, height) {
		padding: 1rem 1.2rem;
	}

	.header-logo {
		display: flex;
		align-items: center;
		transition: opacity 0.3s ease;
		opacity: 1;

		img {
			width: auto;
			height: 50px;
			margin-right: $page_padding-horizontal * 0.5;
		}
	}

	.menu-toggle {
		border: 2px solid transparent;
		padding: 0.25em;
		cursor: pointer;
		z-index: 101;
		svg {
			width: 2rem;
			height: 2rem;
		}
	}

	.only-mobile {
		display: none;
	}

	.menu-toggle,
	.close-menu {
		@include active-state {
			cursor: pointer;
			color: $color-secondary;
		}
		@include responsive(66rem, min) {
			display: none;
		}
	}

	&.menu-open .header-logo {
		opacity: 0.3;
	}

	/* --------------------- DESKTOP NAV --------------------- */
	@include responsive(66rem, min) {
		nav {
			display: flex;
			align-items: center;
			position: relative;
			a {
				margin-left: $page_padding-horizontal * 1.25;
			}
		}

		.menu-toggle {
			display: none;
		}
	}

	/* --------------------- MOBILE NAV  --------------------- */
	@include responsive(66rem) {
		nav {
			display: none;
			position: absolute;
			top: 100%;
			right: 0;
			background: $color-primary;
			transition: transform 0.3s ease;
			transform: translateY(-100%);

			&.menu-open {
				display: block;
				transform: translateY(0);
			}

			a {
				display: block;
			}
		}
	}

	nav {
		.nav-item {
			position: relative;

			@include responsive(66rem, min) {
				&:hover .dropdown-menu {
					display: flex;
				}
			}

			.dropdown-toggle {
				display: flex;
				align-items: center;
				position: relative;
				width: auto;
				svg {
					margin-left: 0.25rem;
					width: 0.9rem;
					height: 0.9rem;
				}
			}

			/* ======================= MOBILE Dropdown ======================= */
			.dropdown-menu {
				display: none;
				position: relative;
				flex-direction: column;
				width: 100%;
				background-color: transparent;
				z-index: 98;
				padding-top: 1.5rem;

				&.open {
					display: flex;
				}

				a {
					display: block;
					padding: 0.5rem;
					color: $color-white;
					border-bottom: 1px solid $color-brand-2;
					text-align: left;
					margin-left: 0;

					&:hover {
						background: $color-brand-1;
					}
				}

				/* ===================== DESKTOP OVERRIDE ===================== */
				@include responsive(66rem, min) {
					display: none;
					position: absolute;
					top: 100%;
					right: -6%;
					width: auto;
					background-color: $color-primary;
					padding-top: 0.5rem;

					&.open {
						display: flex;
					}
				}
			}
		}
	}

	/* ------------------ MOBILE FULL-SCREEN NAV ------------------ */
	@include responsive(66rem) {
		nav {
			position: fixed;
			top: 0;
			left: 0;
			height: 100dvh;
			width: 100%;
			background: $color-brand-1;
			padding: $page_padding-vertical * 3 0;
			flex-direction: column;
			gap: $page-spacing;

			&.menu-open {
				display: flex;
				transform: translateY(0);
			}

			a {
				font-size: 1.5rem;
				font-weight: 200;
				margin-left: $menu-padding-sides;
				&:last-child {
					border-bottom: none;
				}
			}

			.close-menu {
				height: var(--navbar-height_approx);
				padding: $menu-padding-top $menu-padding-sides;
				align-self: flex-end;
			}

			.dropdown-menu {
				position: relative;
				flex-direction: column;
				left: 0;
				top: 0;
				width: 100%;
				box-shadow: none;
				background-color: transparent;

				.only-mobile {
					margin-right: 1rem;
				}

				span {
					display: inline-block;
				}
			}
		}

		nav.menu-open .dropdown-menu a {
			display: flex;
			align-items: flex-start;
			font-size: 1rem;
			text-transform: lowercase;
			padding: 0.85rem $menu-padding-sides * 2;
			text-align: left;
			&:first-child {
				border-top: 1px solid $color-brand-2;
			}
			&:last-child {
				border-bottom: 1px solid $color-brand-2;
			}
		}

		.menu-toggle {
			display: block;
		}
	}

	@include responsive(66rem) {
		.header-logo {
			z-index: 1;
			img {
				height: 2.8rem;
			}
		}
	}
}

/* ---------------------------------------------------
	Clinic DESKTOP & MOBILE
  ----------------------------------------------------- */
nav .nav-item.clinic .dropdown-menu {
	position: relative;
	top: auto;
	left: auto;
	right: auto;
	width: 100%;

	@include responsive(66rem, min) {
		position: absolute;
		top: 100%;
		left: auto;
		right: -35%;
		width: 180px;

		z-index: 98;
	}
}

.content-body {
	padding-top: 6rem;
}

.transparent-header {
	background-color: transparent;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 100;
	transition: background 0.3s ease-in-out;

	a {
		color: inherit;
		&:after {
			border-color: $color-secondary;
		}
	}
}

@include responsive(66rem, min) {
	header.transparent-header nav .dropdown-menu {
		background-color: transparent;
	}
}
