.page-header {
	position: relative;
	text-align: center;
	background-size: cover;
	background-position: center;
	z-index: 50;
	width: 100%;

	.page-header-content {
		position: relative;
		z-index: 2;
		max-width: 65rem;
		margin: 0 auto;
		padding: $page_padding-vertical * 2 $page_padding-horizontal $page_padding-vertical * 1.5;
		color: $color-white;

		h1 {
			margin: 0;
		}

		p {
			padding-top: 1rem;
			margin-bottom: 0;
		}

		.mobile-only {
			display: none;
		}
		@include responsive($page-breakpoint-mobile) {
			.mobile-only {
				display: block;
			}
		}

		.article-meta {
			padding-top: 1rem;
			font-family: $font-body-normal-family;
			@include responsive($breakpoint-tablet) {
				span {
					margin-right: 0.2rem;
					line-height: 1.6;
				}
			}
		}
	}

	&.default {
		background-color: $color-primary;
	}
}

.page-header.article {
	background-blend-mode: overlay;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 300px;
	@include overlay($opacity: 0.6);

	.page-header-content {
		position: relative;

		z-index: 2;

		h1 {
			width: 100%;
			max-width: 65rem;
			margin: 0 auto;
		}
	}

	.image-container {
		position: absolute;
		top: 50%;
		right: 2rem;
		transform: translateY(-50%);
		z-index: 2;
		@include responsive($breakpoint-tablet) {
			top: 15%;
			right: 0.5rem;
		}

		img {
			max-width: 150px;
			max-height: 150px;
			opacity: 0.5;
			@include responsive($breakpoint-tablet) {
				max-width: 80px;
				max-height: 80px;
			}
		}
	}
}
