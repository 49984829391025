.aboutpage {
	--content-max-width: 45rem;
	$button-size: 3rem;
	--container-max-width: 1280px;

	section {
		border-top: 2px solid $color-white;
		@include responsive($page-breakpoint-mobile) {
			border-top: 1px solid $color-white;
		}
	}

	.content {
		p {
			max-width: var(--content-max-width);
		}
		.small-title {
			display: inline-flex;
			font-weight: 500;
			font-family: $font-body-normal-family;
			font-size: 1em;
			text-transform: uppercase;
			letter-spacing: 0.1em;
			margin-bottom: 0;
			.number {
				margin-right: 2rem;
			}
		}
		h2 {
			margin: 1em 0 1.5em;
		}
	}

	@include responsive(75rem) {
		.content {
			p {
				max-width: 100%;
				padding: 0;
			}
		}
	}

	// -----------------------------------------
	// What's NDC / Possums Section
	// -----------------------------------------

	.ndc-possums {
		&.dual-content-section {
			.image-container {
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}

			.inline-image {
				display: none;
			}

			@include responsive(75rem) {
				.inline-image {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 2rem 0;
					align-self: center;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				.image-container {
					display: none;
				}
			}
		}
	}

	// -----------------------------------------
	// Our Mission Section
	// -----------------------------------------
	#our-mission {
		> section {
			margin-top: 0;
		}
	}
	.mission-section {
		position: relative;
		background-image: url('/images/accent-photos/family.avif');
		background-size: cover;
		background-position: 0 40%;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;

		@include overlay($opacity: 0.7);

		@include responsive($page-breakpoint-mobile) {
			background-position: 60% 0;
			padding-top: $page_padding-vertical * 0.5;
			padding-bottom: $page_padding-vertical * 0.5;
			@include overlay($opacity: 0.8);
		}

		p {
			position: relative;
			max-width: 750px;
			z-index: 2;
			margin: 0;
			@include responsive($breakpoint-tablet) {
				margin: 2em 0;
			}
		}
	}

	// -----------------------------------------
	// Programs Section
	// -----------------------------------------

	$program-image-width: 250px;
	$program-image-height: 100px;
	$box-border-radius: 2rem;

	.programs-section {
		.programs-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: auto auto;
			grid-template-areas:
				'ndc psp bsb'
				'. additional-info additional-info';
			gap: 0;
			position: relative;
		}

		.program-box {
			background-size: cover;
			background-position: center;
			padding: 2rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			position: relative;
			overflow: hidden;
			height: 350px;
			justify-content: space-between;

			h3,
			.image-container,
			.button {
				position: relative;
				z-index: 2;
			}

			.program-logo {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				.image-container {
					width: 80%;
					max-width: $program-image-width;
					height: auto;
					margin-bottom: $page_padding-vertical * 0.5;

					img {
						width: 100%;
						height: auto;
						object-fit: contain;
					}
				}
			}
		}

		.ndc {
			@extend .program-box;
			grid-area: ndc;
			background-image: url('/images/accent-photos/ndc.avif');
			border-top-left-radius: $box-border-radius;
			border-bottom-left-radius: $box-border-radius;
			background-position: 10% center;
			@include overlay(rgba(117, 87, 115), 0.9);
			.program-logo {
				.image-container {
					width: 70%;
				}
			}
		}

		.psp {
			@extend .program-box;
			grid-area: psp;
			background-image: url('/images/accent-photos/parents.avif');

			@include overlay(rgba(64, 110, 142), 0.9);
		}

		.bsb {
			@extend .program-box;
			grid-area: bsb;
			background-image: url('/images/accent-photos/breastfeedings.avif');
			border-top-right-radius: $box-border-radius;
			border-bottom-right-radius: $box-border-radius;
			background-position: 0 center;
			@include overlay(rgba(157, 114, 121), 0.9);
		}

		.additional-info {
			grid-area: additional-info;
			padding: $page_padding-vertical * 0.6 0 0;
			text-align: right;
			max-width: var(--content-max-width);
			margin-left: auto;
		}

		@include responsive(75rem) {
			.programs-grid {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: auto auto auto;
				grid-template-areas:
					'ndc ndc'
					'psp bsb'
					'additional-info additional-info';
			}

			.ndc {
				border-top-left-radius: $box-border-radius;
				border-top-right-radius: $box-border-radius;
				border-bottom-left-radius: 0;
			}

			.psp {
				border-radius: 0;
				border-bottom-left-radius: $box-border-radius;
			}

			.bsb {
				border-radius: 0;
				border-bottom-right-radius: $box-border-radius;
			}

			.additional-info {
				padding: $page_padding-vertical * 0.6 0 0;
				text-align: left;
				margin-right: auto;
				max-width: 100%;
			}
		}

		@include responsive(45rem) {
			.programs-grid {
				grid-template-columns: 1fr;
				grid-template-rows: auto auto auto auto;
				grid-template-areas:
					'ndc'
					'psp'
					'bsb'
					'additional-info';
				.program-box {
					height: 300px;
				}
			}

			.ndc,
			.psp,
			.bsb {
				border-radius: 0;
			}

			.ndc {
				border-top-left-radius: $box-border-radius;
				border-top-right-radius: $box-border-radius;
			}

			.bsb {
				border-bottom-left-radius: $box-border-radius;
				border-bottom-right-radius: $box-border-radius;
			}
		}
	}

	// -----------------------------------------
	// Testimonials Section
	// -----------------------------------------

	.testimonial-section {
		position: relative;
		background-image: url('/images/accent-photos/father-daughter.webp');
		background-size: cover;
		background-position: 0 40%;
		background-repeat: no-repeat;

		@include overlay($opacity: 0.7);

		@include responsive($page-breakpoint-mobile) {
			background-position: 50% 0;
		}

		.container {
			z-index: 2;
			text-align: left;
			position: relative;
		}
	}
}
