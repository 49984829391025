.Modal {
	.inner {
		top: calc(50% + var(--navbar-height_approx) / 2);
		transform: translate(-50%, -50%);
		min-width: min(320px, 100vw);
		max-width: min(860px, 100vw);
		max-height: calc(95vh - var(--navbar-height_approx));
	}

	&.blur {
		.overlay {
			backdrop-filter: blur(8px);
			background: rgba($color-white, 0%);
		}
	}

	&.popup-overlay {
		.content {
			border-width: 8px;
			padding: 4rem;

			@include responsive($breakpoint-mobile) {
				padding: 2rem;
			}

			@include responsive($breakpoint-mobile, min, height) {
				padding: 2rem;
			}
		}
		.header {
			border: none;
			padding: 0;
			margin: $spacing-xxxl 0;

			@include responsive($breakpoint-mobile) {
				margin: $spacing-lg 0;
			}
			@include responsive($breakpoint-mobile, min, height) {
				margin: $spacing-lg 0;
			}

			h4 {
				text-align: center;
			}
		}
	}
}

#newsletter-signup {
	p {
		padding: 0;
		margin: 0;
	}

	.footnote {
		text-align: center;
		width: 100%;
		max-width: 480px;
		margin: 3rem auto 0 auto;
		font-size: $font-size-sm;
	}

	@include responsive($breakpoint-mobile) {
		.footnote {
			margin: 1rem auto 0 auto;
		}
	}

	.NewsletterSignup > form {
		margin: $spacing-xxxl 0;
		display: grid;
		gap: $spacing-xxxl;
		grid-template-columns: 1fr 2fr;
		grid-template-areas:
			'name email'
			'submit submit';

		[data-control-name='name'] {
			grid-area: name;
		}

		[data-control-name='email'] {
			grid-area: email;
		}

		label {
			display: flex;
			align-items: flex-start;
		}

		.Button[type='submit'] {
			grid-area: submit;
			justify-self: center;
		}

		@include responsive($breakpoint-mobile) {
			grid-template-columns: 1fr;
			gap: $spacing-lg;
			grid-template-areas:
				'name'
				'email'
				'submit';

			.Button.large-button {
				min-width: unset;
			}
		}
	}
}
