.free-resources-page {
	.category-list {
		width: 100%;
		padding: 0;
		.category-item {
			background-color: lighten($color-brand-2, 30%);
			padding: 15px 20px;
			cursor: pointer;
			position: relative;
			margin: 0 0 1.5em;
			&:hover {
				background-color: lighten($color-brand-2, 20%);
			}
			&:last-child {
				margin-bottom: 0;
			}

			> a {
				display: flex;
				justify-content: space-between;
			}
			.subcategory-list {
				list-style-type: none;
				border-top: 2px solid $color-primary;
				border-bottom: 2px solid $color-primary;
				padding: 3em 1.5em;
				margin-top: 1.5em;
				font-weight: 500;
				background-color: $color-white;

				> li {
					margin-bottom: 1em;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			> svg {
				margin-right: 10px;
				flex-shrink: 0;
			}
		}
	}
}

// ------------------------------------------------------------------
// free-resources/categorySlug/subcategorySlug Page
// ------------------------------------------------------------------

.free-resources-list-page {
	.results-list-container {
		width: 100%;
		h2,
		h3 {
			align-self: flex-start;
		}

		.results-list {
			list-style-type: none;
			margin-bottom: 1em;
			padding: 0;

			> a {
				color: $color-primary;
				word-break: normal;
				cursor: pointer;
			}
			@include active-state {
				cursor: pointer;
			}
			&:last-child {
				margin-bottom: 0;
			}


			.results-item {
				background-color: lighten($color-brand-2, 30%);
				padding: 15px 20px;
				margin: 0 0 1.5em;
				display: flex;
				align-items: center;

				> a {
					color: $color-primary;
					word-break: normal;
				}

				&:hover {
					background-color: lighten($color-brand-2, 20%);
				}

				.icon-container {
					display: flex;
					flex-direction: row;
				}

				svg {
					margin-right: 15px;
					flex-shrink: 0;
				}

				.icon-label {
					display: none;
					font-size: 0.875rem;
				}
			}
		}
	}
}

// Mobile-specific styles
@include responsive($page-breakpoint-mobile) {
	.free-resources-page,
	.free-resources-list-page {
		> section {
			padding: $page_padding-vertical * 0.5 0  $page_padding-vertical * 1.5 0;
		}

		.results-list-container {
			padding: 0;
			text-align: left;

			button,
			h2,
			h3 {
				padding-left: $page_padding-horizontal * 0.5;
				padding-right: $page_padding-horizontal * 0.5;
			}

			.results-item {
				flex-direction: column;

				a {
					align-self: flex-start;
				}

				.icon-container {
					padding: 0 0 0.5em;
					align-self: flex-start;
					color: $color-brand-2;
				}

				svg {
					align-self: center;
					height: 20px;
					width: 20px;
				}

				.icon-label {
					display: inline !important;
				}
			}
		}
	}
}
