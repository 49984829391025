.footer-container {
	position: relative;
	padding: $page_padding-vertical * 1.5 $page_padding-horizontal * 4;
	color: $color-secondary;

	@include responsive($breakpoint-large-desktop) {
		padding: $page_padding-vertical * 1.5 $page_padding-horizontal * 2;
	}
	@include responsive($page-breakpoint-mobile) {
		padding: $page_padding-vertical * 0.5 $page_padding-horizontal;
	}

	&.light-bg-footer {
		background-color: $color-primary;
	}

	&.dark-bg-footer {
		background-color: $color-brand-1;
	}
	a {
		color: inherit;
		&:after {
			border-color: $color-secondary;
		}
	}

	footer {
		display: flex;
		flex-wrap: nowrap;
		gap: $page_padding-horizontal * 4;

		// Left column
		.footer-links {
			flex-grow: 1;
			display: grid;
			grid-template-rows: auto auto auto auto;
			grid-column-gap: $page-spacing * 3;
			justify-items: flex-start;
			align-items: center;
			justify-content: flex-start;
			align-content: space-around;
			grid-auto-flow: column;
		}

		// Right column
		.newsletter {
			text-align: left;
			max-width: 35rem;

			h2 {
				margin: 0;
				padding-bottom: 1.5rem;
			}
			.newsletter-form {
				display: flex;
				flex-wrap: wrap;
				input,
				button {
					padding: 20px;
					border: 1px solid $color-white;
					height: 60px;
				}

				input {
					flex-grow: 1;
					border-right: none;
					margin-bottom: 0;
					font-size: 1rem;
					&::placeholder {
						color: $color-white;
						font-weight: 400;
						opacity: 0.5;
					}
				}
				input[type='email'] {
					color: $color-white;
					font-weight: 400;
				}
			}
		}

		@include responsive($breakpoint-large-desktop) {
			gap: $page-spacing;

			.footer-links {
				grid-column-gap: $page-spacing * 2;
				align-content: center;
				row-gap: $page-spacing * 1;
			}
		}

		@include responsive(75rem) {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: auto auto;
			grid-template-areas:
				'newsletter links'
				'newsletter links';
			gap: 1;

			.footer-links {
				grid-area: links;
				display: flex;
				flex-direction: column;
				justify-content: space-evenly;
				align-items: flex-end;

				a {
					text-align: right;
					&:after {
						right: 0;
						left: unset;
					}
				}
			}

			.newsletter {
				grid-area: newsletter;
				max-width: 100%;

				.newsletter-form {
					display: flex;
					flex-direction: column;

					input,
					button {
						border: 1px solid $color-white;
					}

					input {
						flex-grow: 1;
						border-bottom: none;
					}

					button {
						flex-grow: 1;
					}
				}
			}
		}

		@include responsive(38rem) {
			display: flex;
			flex-direction: column;
			padding: $page_padding-vertical 0 $page_padding-vertical * 0.5;

			.newsletter {
				.newsletter-form {
					flex-direction: column;
				}

				h2 {
					text-align: center;
				}
			}

			.footer-links {
				order: 2;
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: $page-spacing;
				margin: $page-spacing 0;
			}
		}
	}
	.social-media {
		display: flex;
		margin-left: -6px;
		gap: 2rem;
		margin-top: 2rem;

		@include responsive(38rem) {
			justify-content: space-evenly;
			margin-top: 0;
			gap: 0;

			img {
				height: 3rem;
				width: 3rem;
			}
		}
	}

	.logo-overlay {
		position: absolute;
		top: -30px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10;

		&.dark-footer-logo {
			border: 2px solid $color-primary;
			border-radius: 50%;
		}
		img {
			width: 100%;
			height: 100%;
		}
	}
}
