@mixin overlay($color: rgba(52, 82, 55), $opacity: 0.7) {
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color;
		opacity: $opacity;
		z-index: 1;
	}
}

@mixin image-container-bg($image-url) {
	width: var(--image-width);
	height: var(--image-width);
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		mask-image: url(#{$image-url});
		mask-repeat: no-repeat;
		mask-position: center;
		mask-size: contain;
		width: auto;
		aspect-ratio: 1/1;
		object-fit: cover;
		object-position: 35% center;
		height: calc(100% - 15px);
	}

	background-image: url(#{$image-url});
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
