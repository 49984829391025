$page-breakpoint-mobile: 35rem; // 560px
/* $breakpoint-tablet: 65rem; // 1040px */
$page-breakpoint-desktop: 80rem; // 1280px
$breakpoint-tablet: 62.5rem; // 1000px

$breakpoint-large-desktop: 90rem;

$article-list-breakpoint-mobile: 45rem;

@mixin responsive($size, $basis: max, $type: width) {
	@media only screen and (#{$basis}-#{$type}: #{$size}) {
		@content;
	}
}