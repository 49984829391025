.blog-page {
	@include responsive($breakpoint-tablet) {
		> .section-width {
			padding: 0;
		}
	}
	.psp {
		.content-block {
			p {
				max-width: var(--content-max-width);
			}
			h2 {
				padding: 0;
			}
		}
		&.dual-content-section {
			img {
				max-width: 100%;
				max-height: 100%;
			}
		
		}
	}
}
